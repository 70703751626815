import React from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { initializeApp } from "firebase/app";
import { useSelector } from "react-redux";
import { useRef } from "react";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserIdFromAuth } from "../../Redux/actions/GetSellerIdFromAuthActionCreators";
import QrScanner from "react-qr-scanner";
import Leave from "./subcomponent/leave";
import { Rating } from "@mui/material";
import Rating2 from "./subcomponent/rating";
import UpdateUserNameComp from "./subcomponent/profileuserName";
import zIndex from "@mui/material/styles/zIndex";
import UserCard from "./subcomponent/friendscards";
const Background = new URL(
  "../../images/Background Advanced.gif",
  import.meta.url
);
const logo = new URL("../../images/Color_Gradient 2 (1).png", import.meta.url);
const Scan = new URL("../../images/SCAN CODE.png", import.meta.url);
const LOGOUT = new URL("../../images/LOGOUT.png", import.meta.url);

function Profile() {
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState("profile");
  const [show, setShow] = useState("case1");
  const [show3, setShow3] = useState(true);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [Description, setDescription] = useState("");
  const [sign, setSign] = useState("firstVerify");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setCPassword] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [token, setToken] = useState("");
  const [cookie, setCookie] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [gender, setGender] = useState("");
  const [OTP, setOTP] = useState("");
  const [sign2, setSign2] = useState("login");
  const [dob, setDob] = useState();
  const [number, setNumber] = useState("");
  const [lastname, setLastname] = useState("");
  const [showUpload, setUpload] = useState(false);
  const [country, setcountry] = useState("");
  const [file, setFile] = useState("");
  const [isSignUpMode, setIsSignUpMode] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailphoneNumber, setemailPhoneNumber] = useState("");
  const [current, setCurrent] = useState(false);
  const [currentpass, setCurrentpass] = useState("");
  const [leave, setLeave] = useState(false);
  const [code, setCode] = useState(0);
  const [photorate, setPhotorate] = useState("");
  const [startTime ,setStarttime]=useState("")
  const [finishTime , setFinishtime ]=useState("")
  const [RID,setRideID]=useState("")
  const [amount, setamount]= useState("")
  const [location,setlocation]=useState("")
  const [ridename ,setRidename]=useState("")
  const baseUrl = "https://server.youthbuzz.in";
  const baseUrls = "http://localhost:8000";
  const id = "66d732e894ecf1e3b616a6f3"
  const navigate = useNavigate("");
  const dispatch = useDispatch("");
  useEffect(() => {
    if (!id) {
      navigate("/login", {
        replace: true,
        state: {
          signIn: true,
        },
      });
    } else {
      navigate("/userProfile");

      // Assuming fetchData is a function you want to call when 'id' is truthy
    }
  }, [navigate, id]);
  useEffect(() => {
    fetchData();
  }, []);
  const handlec = () => {
    setLeave(false);
  };
  const Deletereq = async (reqID) => {
    console.log(reqID, id);
    try {
      const res = await axios.patch(
        `${baseUrl}/api/v1/user/deletereq/${id}/${reqID}`
      );

      if (res) {
        window.location.reload();
      }
    } catch (error) {
      alert("error");
      console.log(error);
    }
  };
  const Accept = async (chat, id2, reqID, fname, photo2) => {
    try {
      const res = await axios.patch(`${baseUrl}/api/v1/user/accept/${id}`, {
        UserId: chat,
        friendId: id2,
        userPhoto1: photo,
        userPhoto2: photo2,
        friendname1: userName,
        friendname2: fname,
      });
      if (res) {
        alert("accpted");
        Deletereq(reqID);
      }
    } catch (error) {
      alert("somthing went wrong");
    }
  };

  const ViewProfile = async (id) => {
    navigate(`/myFriend/${id}`);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/user/getOneuser/${id}`
      );
      setData([response.data.data.user]);
      console.log(response);
    } catch (error) {}
  };
  const handleLogout = async (e) => {
    e.preventDefault();

    dispatch(getUserIdFromAuth(""));
    toast.success("You logged Out Successfully");
    navigate("/login");
  };
  const [timeRemaining, setTimeRemaining] = useState("");
  const [MainRideId, setMainRideId] = useState("");

  const handleLeaveUpdateUser = async () => {
    try {
      const res = await axios.patch(
        `https://server.youthbuzz.in/api/v1/user/leave/${id}`,
        {
          MainRideId: MainRideId,
          finishtime:finishTime,
          starttime:startTime,
          location:location,
          RideName:ridename,
          amount:amount,
          RID:RID
        }
      );
      console.log(res);
      if (res.data.status == true) {
        window.location.reload();
      }
      setLeave(false);
    } catch (error) {}
  };
  const hideRate = () => {
    setRating("");
  };

  const LeaveShow = () => {
    setLeave(true);
    window.scrollTo(0, 0);
  };

  const videoRef = useRef(null);
  const [scanneropen, setopenScan] = useState(false);
  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };
    if (scanneropen) {
      startCamera();
    }
    return () => {
      const video = videoRef.current;

      if (video) {
        const stream = video.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    };
  }, []);
  const handleScan = (result) => {
    if (result) {
      console.log("qr folund");
      console.log(result, "hhhhj");
      // Use a regex to check if the result is a valid URL

      if (result) {
        // Navigate to the detected URL

        window.location.href = result.text;
        setopenScan(false);
      } else {
        console.error("Invalid URL format");
      }
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/user/update/${id}`,
        {
          Description: Description,
          lastname: lastname,
          name: name,
          userName: userName,
          RID:RID

          // isEmailVerified: isEmailVerified
        }
      );
      // dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.name, response.data.data.user.email));
      if (response.data.status === "success") {
        toast("Profile updated success");

        // dispatch(getUserIdFromAuth(response.data.data.user._id,  response.data.data.user.name, response.data.data.user.email));
        console.log(response.data.data.user._id);

        // settoken(response.data.token);
        // navigate("/home")
        // console.log(response.data.data.user.name)
        // settoken(response.data.token);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateUserName = async (userName) => {
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/user/update/${id}`,
        {
          userName: userName,

          // isEmailVerified: isEmailVerified
        }
      );
      // dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.name, response.data.data.user.email));
      if (response.data.status === "success") {
        toast.success("Profile updated success");
        window.location.reload();

        // dispatch(getUserIdFromAuth(response.data.data.user._id,  response.data.data.user.name, response.data.data.user.email));
        console.log(response.data.data.user._id);

        // settoken(response.data.token);
        // navigate("/home")
        // console.log(response.data.data.user.name)
        // settoken(response.data.token);
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Game Name Already Exists or Somthing Went Wrong ");
    }
  };

  useEffect(() => {
    if (data === undefined || data.length === 0) return;
    setDescription(data[0].Description !== null ? data[0].Description : "");
    setName(data[0].name !== null ? data[0].name : "");
    setUserName(data[0].userName !== null ? data[0].userName : "");
    setLastname(data[0].lastname !== null ? data[0].lastname : "");
    setPhotorate(data[0].photo !== null ? data[0].photo : "");
    setPhoto(data[0].photo !== null ? data[0].photo : "");
    if (data[0].current && data[0].current.length > 0) {
      setMainRideId(
        data[0].current[0].MainRideId !== null
          ? data[0].current[0].MainRideId
          : "GVGH"
      );
      setlocation(data[0].current[0].location !== null
        ? data[0].current[0].location
        : "GVGH")
        setStarttime(data[0].current[0].timeOfBooking !== null
          ? data[0].current[0].timeOfBooking
          : "GVGH")
          setFinishtime(data[0].current[0].
            timeRemaining !== null
            ? data[0].current[0].
            timeRemaining
            : "GVGH")
            setRidename(data[0].current[0].RideName !== null
              ? data[0].current[0].RideName
              : "GVGH")
              setamount(data[0].current[0].coinConsumed !== null
                ? data[0].current[0].coinConsumed
                : "GVGH")
            
                  setRideID(data[0].current[0].RID !== null
                    ? data[0].current[0].RID
                    : "GVGH")
                  
    } else {
      setMainRideId("GVGH"); // Fallback value if current is not defined or empty
    }
  }, [data]);
  console.log(startTime,finishTime,RID,"ghhgujh");
  const handleLeaveUpdateRide = async () => {
    try {
      const res = await axios.patch(
        `https://server.youthbuzz.in/api/v1/ride/leaveRide/${MainRideId}`
      );
      handleLeaveUpdateUser();
    } catch (error) {}
  };
  const handleError = (error) => {
    console.error("Error accessing camera:", error);
  };
  const [flashOn, setFlashOn] = useState(false);
  const toggleFlash = () => {
    setFlashOn(!flashOn);
  };
  const [showRating, setRating] = useState();
  //   profile update
  const handleSubmitphoto = async (e) => {
    e.preventDefault();

    toast.success("image uploaded");
    const formData = new FormData();
    formData.append("photo", file);
    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/user/updatePhoto/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // Handle the response from the server
      if (response.data.status === "success") {
      }
      // setFile(response.data);
    } catch (error) {
      setUpload(false);
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.value);
    setFile(event.target.files[0]);
    // setUpload("show")
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(
        `${baseUrl}/api/v1/user/updatepass/${id}`,
        {
          // lastname:lastname,
          passwordCurrent: currentpass,
          password: password,

          // isEmailVerified: isEmailVerified
        }
      );

      if (response.data.statusbar === "success") {
        toast.success("Password Updated");

        console.log(response);
      }
      // if (response.data.statusbar === "success") {
      //   dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.lastname, response.data.data.user.name, response.data.data.user.email));
      //   navigate("/home")
      // }
    } catch (error) {
      console.log(error);
      toast.error("Current password is wrong");
      // if (error.message === "Request failed with status code 403") {
      //   setsign("OTP")

      // }
    }
  };

  const handleScanOpen = () => {
    setopenScan(true);
    window.scrollTo(0, 0);
  };
  return (
    <>
      {scanneropen && (
        <div style={{ width: "100%", textAlign: "center" }}>
          {" "}
          <Button
            style={{
              backgroundColor: "#0d4f74",
              color: "white",
              position: "relative",
              top: "80vh",
              fontFamily: "'Rajdhani',sans-serif",
              zIndex: "99999",
            }}
            onClick={() => setopenScan(false)}
          >
            Close
          </Button>
        </div>
      )}
      {scanneropen && (
        <div className="scanner-container">
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%", height: "50vh" }}
            facingMode="environment"
            facingModeChanged={(value) => {
              if (value === "user") {
                // Flash is not supported when using the front camera
                setFlashOn(false);
              }
            }}
            constraints={{
              video: {
                facingMode: "environment",
                torch: flashOn,
              },
            }}
          />
        </div>
      )}
      {data.map((item) => {
        return (
          <div className={`video3 ${scanneropen ? "blurred-background" : ""}`}>
            <div className="nav">
              <div className="logo" style={{ width: "200px" }}>
                <Link to="/">
                  {" "}
                  <img width="100%" src={logo}></img>
                </Link>
              </div>
              <div>
                <button
                  onClick={handleLogout}
                  style={{ background: "#0d4f74" }}
                  variant="contained"
                  className="logout pc"
                >
                  <img width="70%" src={LOGOUT}></img>
                </button>
              </div>
            </div>
            <video
              src={Background}
              disablePictureInPicture
              autoplay="autoplay"
              muted
              className="video2"
              loop
            ></video>
            {!userName && (
              <div>
                <UpdateUserNameComp
                  style={{ zIndex: "2000" }}
                  func={UpdateUserName}
                />
              </div>
            )}
            {userName && (
              <div className="abso">
                {edit == "profile" && (
                  <div className="profile-main">
                    <div className="mobile">
                      <EditNoteOutlinedIcon
                        onClick={() => setEdit("edit")}
                        style={{
                          color: "white",
                          position: "absolute",
                          cursor: "pointer",
                          top: "59px",
                          right: "50px",
                        }}
                      />
                    </div>
                    <div>
                      <h2 className="p-name pc">{item.name}</h2>
                      <h2 className="p-name pc" style={{ fontSize: "20px" }}>
                        {item.userName}
                      </h2>
                    </div>
                    <div className="profile-des">
                      <div className="profile-pic">
                        <img
                          width="100%"
                          height="150px"
                          style={{ borderRadius: "50%" }}
                          src={`https://youthbuzzdata.s3.ap-south-1.amazonaws.com/${item.photo}`}
                        ></img>
                      </div>
                      <h2 className="p-name mobile">{item.name}</h2>
                      <h2
                        className="p-name mobile"
                        style={{ fontSize: "20px" }}
                      >
                        {item.userName}
                      </h2>
                      <div className="profile-des-para-main">
                        <h2 className="profile-des-para">{item.Description}</h2>
                        <div className="pc">
                          <EditNoteOutlinedIcon
                            style={{
                              color: "white",
                              cursor: "pointer",
                              position: "",
                            }}
                            onClick={() => setEdit("edit")}
                          />
                        </div>
                        <div>
                          <button
                            onClick={handleLogout}
                            style={{
                              background: "#0d4f74",
                              margin: "10px auto",
                            }}
                            variant="contained"
                            className="logout mobile"
                          >
                            <img width="70%" src={LOGOUT}></img>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="profile-more">
                      <div className="profile-coin">
                        <div className="profile-flex1">
                          <h3 className="profile-wallet-main2">
                            COINS:{item.yourCoin}
                          </h3>
                        </div>
                        <div className="profile-flex2">
                          {" "}
                          <h3 className="profile-wallet-main2">
                            NEWS AND BLOGS
                          </h3>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="profile-more" style={{ height: "auto" }}>
                      <div className="profile-flex" style={{ height: "auto" }}>
                        <div
                          className="profile-flex1"
                          style={{ flexBasis: "100%", height: "auto" }}
                        >
                          {" "}
                          <h3 className="profile-wallet-main">Current Ride</h3>
                          {item.current.length !== 0 ? (
                            item.current.map((item2) => {
                              return (
                                <div style={{ position: "relative" }}>
                                  {leave && (
                                    <Leave
                                      Leave={handleLeaveUpdateRide}
                                      cancil={handlec}
                                    />
                                  )}
                                  <h3
                                    className="profile-wallet-main"
                                    style={{ fontSize: "20px" }}
                                  >
                                    {item.current[0].RideName}
                                  </h3>
                                  <div className="current-flex">
                                    <div className="photo-current">
                                      <img
                                        width="100%"
                                        style={{ borderRadius: "5PX" }}
                                        height="200px"
                                        src="https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg"
                                      ></img>
                                    </div>
                                    <div>
                                      <p className="history">
                                        Location:{item2.location}
                                      </p>
                                      <p className="history">ID:{item2.RID}</p>
                                      <p className="history">
                                        Time of booking:
                                        {item2.timeOfBooking.slice(15, 24)}
                                      </p>
                                      <p className="history">
                                        Coin counsumed:{item2.coinConsumed}
                                      </p>
                                      <p className="history">
                                        Time End:
                                        {item2.timeRemaining.slice(11, 19)}
                                      </p>
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    style={{
                                      background: " #0e1b23",
                                      width: "200px",
                                    }}
                                    variant="contained"
                                    className="Leave"
                                    onClick={LeaveShow}
                                  >
                                    LEAVE
                                  </button>
                                </div>
                              );
                            })
                          ) : (
                            <div className="profile-wallet-main">
                              No Current Ride :(
                            </div>
                          )}
                        </div>
                        {/* <div className="profile-flex2">
                        <h3 className="profile-wallet-main" style={{margin:"auto"}}>
                          Pervious history
                        </h3>
                        <div className="history-main">
                        {item.currentAndPrevious.map((item2) => {
                          return (
                           
                              <div className="history-div">
                                <p className="history">
                                  Ride Name: {item2.RideName}
                                </p>
                                <p className="history">
                                  Time: {item2.timeOfBooking}
                                </p>
                              </div>
                        
                          );
                        })}
                            </div>
                      </div> */}
                      </div>
                    </div>
                    <div className="profile-more" style={{ height: "auto" }}>
                      <div className="profile-flex" style={{ height: "auto" }}>
                        <div
                          className="profile-flex1"
                          style={{ flexBasis: "100%", height: "540px" }}
                        >
                          <h3 className="profile-wallet-main">Pervious Ride</h3>
                          <div
                            style={{
                              overflowY: "scroll",
                              width: "100%",

                              height: "450px",
                              marginTop: "10px",
                            }}
                          >
                            {item.currentAndPrevious.length !== 0 ? (
                              item.currentAndPrevious.map((item2) => {
                                return (
                                  <div
                                    style={{
                                      border: "",
                                      width: "100%",
                                      paddingBottom: "20px",
                                      position: "relative",
                                    }}
                                  >
                                    {showRating == `${item2._id}` && (
                                      <Rating2
                                        id={item2.RID}
                                        name={name}
                                        photo={photorate}
                                        func={hideRate}
                                      />
                                    )}
                                    <h3
                                      className="profile-wallet-main"
                                      style={{ fontSize: "20px" }}
                                    >
                                      {item2.RideName}
                                    </h3>
                                    <div className="current-flex">
                                      <div className="photo-current">
                                        <img
                                          width="100%"
                                          style={{ borderRadius: "5PX" }}
                                          height="200px"
                                          src="https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg"
                                        ></img>
                                      </div>
                                      <div>
                                        <p className="history">
                                          Location:{item2.location}
                                        </p>
                                        <p className="history">
                                          ID:{item2.RID}
                                        </p>
                                        <p className="history">
                                          Time of booking:{" "}
                                          {item2.timeOfBooking.slice(15, 24)}
                                        </p>
                                        <p className="history">
                                          Coin counsumed:{item2.coinConsumed}
                                        </p>
                                        <p className="history">
                                          Finish time:{" "}
                                          {item2.finishTime.slice(11, 19)}
                                        </p>
                                      </div>
                                    </div>
                                    <button
                                      type="submit"
                                      style={{
                                        background: " #0e1b23",
                                        width: "200px",
                                      }}
                                      variant="contained"
                                      className="Leave"
                                      onClick={() => setRating(item2._id)}
                                    >
                                      RATE
                                    </button>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="profile-wallet-main">
                                No Pervious Ride
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="profile-flex2">
                        <h3 className="profile-wallet-main" style={{margin:"auto"}}>
                          Pervious history
                        </h3>
                        <div className="history-main">
                        {item.currentAndPrevious.map((item2) => {
                          return (
                           
                              <div className="history-div">
                                <p className="history">
                                  Ride Name: {item2.RideName}
                                </p>
                                <p className="history">
                                  Time: {item2.timeOfBooking}
                                </p>
                              </div>
                        
                          );
                        })}
                            </div>
                      </div> */}
                      </div>
                    </div>
                    <div className="profile-more" style={{ height: "auto" }}>
                      <div className="profile-flex" style={{ height: "auto" }}>
                        <div
                          className="profile-flex1"
                          style={{ flexBasis: "100%", height: "auto" }}
                        >
                          <div>
                            <h3 className="profile-wallet-main">
                              Friend Request
                              {data.map((item) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item.userFriends.length !== 0 ? (
                                      item.userFriends.map((item2) => {
                                        return (
                                          <>
                                            <UserCard
                                              button={"Accept"}
                                              name={item2.UserName}
                                              pic={`https://youthbuzzdata.s3.ap-south-1.amazonaws.com/${item2.friendPhoto}`}
                                              func={() =>
                                                Accept(
                                                  item2.UserId,
                                                  item2.friendId,
                                                  item2._id,
                                                  item2.UserName,
                                                  item2.friendPhoto
                                                )
                                              }
                                            />
                                          </>
                                        );
                                      })
                                    ) : (
                                      <div
                                        className="profile-wallet-main"
                                        style={{ textAlign: "center" }}
                                      >
                                        No friend request :(
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-more" style={{ height: "auto" }}>
                      <div className="profile-flex" style={{ height: "auto" }}>
                        <div
                          className="profile-flex1"
                          style={{ flexBasis: "100%", height: "auto" }}
                        >
                          <div>
                            <h3 className="profile-wallet-main">My Friends </h3>
                            {data.map((item) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                  }}
                                >
                                  {item.userFriendAccepted.length !== 0 ? (
                                    item.userFriendAccepted.map((item2) => {
                                      return (
                                        <>
                                          <UserCard
                                            button={"View profile"}
                                            name={item2.Friendname}
                                            pic={`https://youthbuzzdata.s3.ap-south-1.amazonaws.com/${item2.friendPhoto}`}
                                            func={() =>
                                              ViewProfile(item2.friendId)
                                            }
                                          />
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div
                                      className="profile-wallet-main"
                                      style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      No friends :(
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <button
                        onClick={handleScanOpen}
                        style={{
                          padding: "20px 30px 20px 30px",
                          marginTop: "10px",
                        }}
                        className="join-btn"
                      >
                        <QrCodeScannerOutlinedIcon />
                        {/* <img width="200px" src={Scan}></img> */}
                      </button>
                    </div>
                  </div>
                )}
                <br></br>

                {edit == "edit" && (
                  <div className="edit-user">
                    <div
                      className="profile-main"
                      style={{ textAlign: "center" }}
                    >
                      <form
                        className="updateProfile"
                        onSubmit={handleSubmitphoto}
                      >
                        <br></br>
                        <h4
                          style={{ textAlign: "center", margin: "auto" }}
                          className="bookfont"
                        >
                          UPLOAD PHOTO
                        </h4>
                        <input
                          onChange={handleFileChange}
                          className="input-form"
                          type="file"
                        ></input>
                        <button
                          type="submit"
                          style={{ marginTop: "20px" }}
                          className="join-btn2"
                        >
                          <SaveAltIcon />
                        </button>
                      </form>
                      <form className="updateProfile" onSubmit={handleSignUp}>
                        <br></br>
                        <h4
                          style={{ textAlign: "center", margin: "auto" }}
                          className="bookfont"
                        >
                          PERSONAL DETIALS
                        </h4>
                        <input
                          type="text"
                          placeholder={name}
                          onChange={(e) => setName(e.target.value)}
                          className="input-form"
                          value={name}
                        ></input>
                        <input
                          type="text"
                          placeholder={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          className="input-form"
                          value={userName}
                        ></input>
                        <input
                          type="text"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          placeholder={lastname}
                          className="input-form"
                        ></input>
                        <textarea
                          rows="10"
                          value={Description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={Description}
                          className="input-form"
                        ></textarea>
                        <button
                          style={{ marginTop: "20px" }}
                          type="submit"
                          className="join-btn2"
                        >
                          <SaveAltIcon />
                        </button>
                      </form>
                      <form className="updateProfile" onSubmit={handleLogin}>
                        <br></br>
                        <h4
                          style={{ textAlign: "center", margin: "auto" }}
                          className="bookfont"
                        >
                          UPDATE PASSWORD
                        </h4>
                        <input
                          type="password"
                          value={currentpass}
                          onChange={(e) => setCurrentpass(e.target.value)}
                          className="input-form"
                          placeholder="Current password"
                        ></input>

                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="input-form"
                          placeholder="Confrim password"
                        ></input>
                        <button
                          type="submit"
                          style={{ marginTop: "20px" }}
                          className="join-btn2"
                        >
                          <SaveAltIcon />
                        </button>
                      </form>
                      <button
                        onClick={() => setEdit("profile")}
                        className="join-btn2"
                      >
                        <ReplyAllIcon />
                      </button>
                    </div>
                    <br></br>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}

export default Profile;
