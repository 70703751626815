import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getUserIdFromAuth } from "../../Redux/actions/GetSellerIdFromAuthActionCreators";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { Slider } from "@mui/material";
import Slider2 from "./subcomponent/ratingSlider";
const register = new URL("../../images/a1_White-01 (1).png", import.meta.url);

const Background = new URL(
  "../../images/Background Advanced.gif",
  import.meta.url
);
const Background2 = new URL(
  "../../images/image-removebg-preview.png",
  import.meta.url
);
const Background3 = new URL(
  "../../images/A new era of LBE XR Gaming.png",
  import.meta.url
);
const joinNow = new URL("../../images/JOIN NOW.png", import.meta.url);
const vrspaceShip = new URL(
  "../../images/THE VR Spaceship.png",
  import.meta.url
);

const book = new URL("../../images/BOOK.png", import.meta.url);
const cbook = new URL("../../images/confirm Booking.png", import.meta.url);

function Rides() {
  const baseUrl = "https://server.youthbuzz.in";
  const baseUrls = "http://localhost:8000";
  const { resetToken } = useParams();
  const navigate = useNavigate("");


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [rating, setRating] = useState([]);

  const [data, setData] = useState([]);
  
    const [timeSlot, setTimeSlot] = useState("1");
  const [amount, setAmont] = useState("");
  const [Status, setStatus] = useState("");
  const [bookStatus, setBookstatus] = useState("");
  const [booking, setbooking] = useState("");
  const [overallRating, setOverallRating] = useState(0);

  // const handleTime = (e) => {
  //   e.preventDefault();
  //   setAmont(timeSlot);
  // };
  useEffect(() => {
    handleRide();
  }, []);
  const ratingsArray = [];
  useEffect(() => {
    if (data === undefined || data.length === 0) return;
    setStatus(data[0].Status !== null ? data[0].Status : "");
    setbooking(data[0].Status !== null ? data[0].Status : "");

    data.forEach((item) => {
      item.Reviews?.forEach((review) => {
        const userRating = review.userRating;
        if (userRating !== null && userRating !== undefined) {
          console.log(userRating, "hii");
          ratingsArray.push(userRating)
        }
      });
    });

    setRating(ratingsArray)
    if (ratingsArray.length > 0) {
      const sumOfRatings = ratingsArray.reduce((sum, rating) => sum + rating, 0);
      const averageRating = sumOfRatings / ratingsArray.length;
      setOverallRating(averageRating);
    }


    
  }, [data]);
  
 
  const id = "66d732e894ecf1e3b616a6f3"
  const [data2,setdata2]=useState([])
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/user/getOneuser/${id}`
      );
      setdata2([response.data.data.user]);
    
    } catch (error) {}
  };

  useEffect(()=>{
    fetchData()
  })
  const [MainRideId,setMainRideId]=useState("")
  useEffect(() => {
    if (data2 === undefined || data2.length === 0) return;

    if (data2[0].current && data2[0].current.length > 0) {
      setMainRideId(data2[0].current[0].MainRideId !== null ? data2[0].current[0].MainRideId : "");
    } else {
      setMainRideId(""); // Fallback value if current is not defined or empty
    }
  }, [data2]);
  
  const handleBook=(e)=>{
  setBookstatus("form")
  window.scrollTo(0,0)
  }
 
  const RideBuy = async (e) => {
    e.preventDefault();

    try {
      if (Status == "Avail" && MainRideId.length==0) {
        const res = await axios.post(
          `${baseUrl}/api/v1/booking/BookRide/1184`,
          {
            Coins: timeSlot,
            RideName: "Vr Spaceship",
            Slot: timeSlot,
          }
        );

        if (res.data.status == "Success") {
          buytest();
          setBookstatus("processing");
        }
      } else {
        toast.error("Booked");
        setBookstatus("");
      }
    } catch (error) {
      toast.error("somthing went wrong");
    }
  };

  const RideStatus = async () => {
    try {
      const res = await axios.patch(
        `${baseUrl}/api/v1/ride/updateRideStatus/6597d231ae1baa8234a9d881`,
        {
          timeslot: timeSlot,
        }
      );
      console.log(res);
      if (res.data.status == "Success") {
        alert("your slot is booked");
      }
    } catch (error) {
      alert("Booked Try Again Later");
    }
  };
  
  const buytest = async () => {
    console.log("hi");
    try {
      const res = await axios.patch(
        `https://server.youthbuzz.in/api/v1/user/updatecoin/${id}`,
        {
          amount: timeSlot,
          RideName: "Vr Spaceship",
          RID: "1184",
          location: "GIXTC,Noida, Uttar Pradesh, India",
          coinConsumed: timeSlot,
          MainRideId: "6597d231ae1baa8234a9d881",
        }
      );
      console.log(res, "hii2");
      if (res.data.status == true) {
        setBookstatus("confirm");
        RideStatus();
        navigate("/userProfile");
        toast.success("Ride Booked");
      }
    } catch (error) {}
  };

  const handleRide = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/v1/ride/getOneRide/6597d231ae1baa8234a9d881`
      );
      setData([res.data.data.ride]);
      console.log(res, "hukhk");
    } catch (err) {
      console.log(err, "<--- err in api fetching");
    }
  };
  useEffect(() => {
    if (!id) {
      navigate("/login", {
        replace: true,
        state: {
          signIn: true,
        },
      });
    } else {
      navigate("/rides/1184/VR Spaceship");

      // Assuming fetchData is a function you want to call when 'id' is truthy
    }
  }, [navigate, id]);
  return (
    <>
      {bookStatus == "form" && (
        <div>
          {data.map((item) => {
            return (
              <form onSubmit={RideBuy}>
                <div
                  style={{ position: "absolute", width: "100%", zIndex: "100" }}
                >
                  <div className="bookslot">
                    <h1
                      style={{
                        color: "white",
                        fontFamily: "Rajdhani, sans-serif",
                        marginTop: "10px",
                      }}
                    >
                      VR SPACESHIP
                    </h1>
                    <img
                      width="200px"
                      height="200px"
                      src="https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg"
                    ></img>
                    <div className="bookfont"> Status:{item.Status}</div>
                    <div className="bookfont" style={{ display: "flex" }}>
                      Book for:
                      <select
                        className="timeselection"
                        required
                        value={timeSlot}
                        onChange={(e) => setTimeSlot(e.target.value)}
                      >
                        <option value="1">1</option>
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                        <option value="60">60</option>
                      </select>{" "}
                      min
                    </div>
                    <div className="bookfont">
                      cost:
                      <input
                        type="text"
                        disabled
                        required
                        className="bookfont"
                        style={{
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          margin: "0px",
                          width: "60px",
                        }}
                        value={timeSlot}
                        onChange={(e) => setTimeSlot(e.target.value)}
                      ></input>
                    </div>
                    <button type="submit" className="confirm">
                      <img style={{ width: "100%" }} src={cbook}></img>
                    </button>
                    <br></br>
                  </div>
                </div>
              </form>
            );
          })}
        </div>
      )}

      {bookStatus == "processing" && (
        <div>
          {data.map((item) => {
            return (
              <div
                style={{ position: "absolute", width: "100%", zIndex: "100" }}
              >
                <div className="bookslot">
                  <h1
                    style={{
                      color: "white",
                      fontFamily: "Rajdhani, sans-serif",
                      marginTop: "10px",
                    }}
                  >
                    VR SPACESHIP
                  </h1>
                  <img
                    width="200px"
                    height="200px"
                    src="https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg"
                  ></img>
                  <h4 className="bookfont">Processing booking...........</h4>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {bookStatus == "confirm" && (
        <div>
          {data.map((item) => {
            return (
              <div
                style={{ position: "absolute", width: "100%", zIndex: "100" }}
              >
                <div className="bookslot">
                  <h1
                    style={{
                      color: "white",
                      fontFamily: "Rajdhani, sans-serif",
                      marginTop: "10px",
                    }}
                  >
                    VR SPACESHIP
                  </h1>
                  <img
                    width="200px"
                    height="200px"
                    src="https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg"
                  ></img>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4
                      style={{ textAlign: "center", margin: "10px auto" }}
                      className="bookfont"
                    >
                      Booking Confirmed!
                    </h4>
                    <h4 className="bookfont">Start Time:</h4>
                    <h4 className="bookfont">End Time:</h4>
                    <div onClick={() => setBookstatus("")} className="confirm">
                      <ReplyAllIcon />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div>
        {data.map((item) => {
          return (
            <div>
              <div style={{ height: "auto" }} className="video">
                <div
                  style={{
                    position: "fixed",
                    width: "400px",
                    bottom: "30px",
                    width: "100%",
                    zIndex: "99",
                  }}
                  className={`profile-b ${
                    bookStatus ? "blurred-background" : ""
                  }`}
                >
                  <div className="rides-book-button">
                    <button
                      onClick={handleBook

                                      
                      }
                      className="join-btn ride-mobile-book"
                    >
                      <img src={book}></img>
                    </button>
                  </div>
                </div>

                <div>
                  <div
                    style={{ paddingTop: "100px" }}
                    className={`profile-b ${
                      bookStatus ? "blurred-background" : ""
                    }`}
                  >
                    <div className="img-game">
                      <div className="RidePic">
                        <h1
                          style={{
                            color: "white",
                            fontFamily: "Rajdhani, sans-serif",
                            marginTop: "10px",
                          }}
                        >
                          VR SPACESHIP
                        </h1>
                        <br></br>
                        <img
                          className="ride-main-pic"
                          src={`https://ourcadium.s3.ap-south-1.amazonaws.com/Ride%20Thumbnails%20and%20Gallery%20Images/thumbnail-6597d231ae1baa8234a9d881-1704448736153.jpeg`}
                        ></img>

                        <h2
                          style={{
                            textAlign: "left",
                            color: "white",
                            marginTop: "50px",
                          }}
                        >
                          Location:{item.Location}
                        </h2>
                        <h2 style={{ textAlign: "left", color: "white" }}>
                          Rating:{overallRating.toFixed(1)}
                        </h2>
                        <h2 style={{ textAlign: "left", color: "white" }}>
                          ID:{item.RideID}
                        </h2>

                        <Carousel
                          minimumTouchDrag={80}
                          swipeable={true}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlaySpeed={4000}
                          autoPlay={true}
                          keyBoardControl={true}
                          transitionDuration={500}
                          containerClass="rideSlide"
                        >
                          {item.Gallery.map((item2) => {
                            return (
                              <div>
                                <img
                                  width="100%"
                                  src={`https://ourcadium.s3.ap-south-1.amazonaws.com/${item2}`}
                                ></img>
                              </div>
                            );
                          })}
                        </Carousel>
                        <p className="ridedes">{item.Description}</p>
                        <Carousel
                          minimumTouchDrag={80}
                          swipeable={true}
                          draggable={true}
                          showDots={false}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          infinite={true}
                          autoPlaySpeed={4000}
                          autoPlay={true}
                          keyBoardControl={true}
                          transitionDuration={500}
                          containerClass="rideSlide"
                        >
                          {item.Reviews.length != 0 ? (
                            item.Reviews.map((review) => {
                              return (
                                <>
                                  <Slider2
                                    name={review.userName}
                                    value={review.userRating}
                                    text={review.userReview}
                                    photo={`https://youthbuzzdata.s3.ap-south-1.amazonaws.com/${review.userPhoto}`}
                                  />
                                </>
                              );
                            })
                          ) : (
                            <div>No Rating</div>
                          )}
                        </Carousel>
                      </div>

                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <video
                  src={Background}
                  disablePictureInPicture
                  autoplay="autoplay"
                  muted
                  className="video2"
                  loop
                ></video>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Rides;
